<template>
  <main class="final">
    <div class="container">
      <header class="final__header">
        <img class="final__logo" :src="logo" alt="Final page logo" />
      </header>
      <section class="final__content">
        <p class="final__text">
          A number of factors contribute to shrink each year. However, in
          <span>2021</span>, <span>40%</span> of all shrink was
          <i>CONTROLLABLE</i>. Through a combination of leadership engagement
          and associate behaviors, we can reduce shrink in the pursuit of
          profit!
        </p>
        <p class="final__subtext">
          {{ subtext }}
        </p>
        <slot name="rectangle"></slot>
      </section>
      <section class="final__footer">
        <slot name="footer"></slot>
      </section>
    </div>
  </main>
</template>

<script>
import logo from "../assets/images/icons/logo-with-hand.svg";

export default {
  name: "FinalLayout",
  props: ["subtext"],
  data() {
    return {
      logo,
    };
  },
};
</script>

<style scoped lang="scss">
.final {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.container {
  width: 100%;
  max-width: 64rem;
  padding: 3rem 1.5rem;
  margin-inline: auto;
}

.final__logo {
  display: block;

  max-width: 100%;
  height: auto;
  margin-inline: auto;
}

.final__content {
  position: relative;

  margin-top: 7.4rem;
}

.final__text {
  font-family: "RosarioBold", sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.3;
  text-align: center;
}

.final__subtext {
  max-width: 50rem;
  margin: 3rem auto 4rem;

  font-family: "RosarioBold", sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
}

.final__thank-phrase {
  font-family: "RosarioBold", sans-serif;
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 1.2;
  text-align: center;
}

.final__footer {
  margin: 6.6rem auto 0;
  max-width: 28.1rem;
  width: 100%;
}

@media (max-width: 1440px) {
  .container {
    max-width: 82rem;
    padding-top: 9rem;
  }

  .final__text {
    font-size: 2.25rem;
  }

  .final__subtext {
    font-size: 2rem;
  }

  .final__thank-phrase {
    font-size: 6rem;
  }

  .final__footer {
    max-width: 35rem;
  }
}

@media (max-width: 991px) {
  .final__logo {
    max-width: 30rem;
    margin-inline: auto;
  }

  .final__content {
    margin-top: 5rem;
  }

  .final__text {
    font-size: 1.75rem;
  }

  .final__thank-phrase {
    margin-top: 5rem;

    font-size: 3.75rem;
  }

  .final__footer {
    margin-top: 5rem;
    max-width: 18rem;
  }
}

@media (max-width: 991px) {
  .container {
    padding-top: 14rem;
  }
}
</style>
