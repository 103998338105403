<template>
  <FinalLayout
    subtext="What is YOUR store’s Shrink? Enter your store number below to find out!"
  >
    <template v-slot:rectangle>
      <ShrinkRectangle :shrink="shrink" :showShrink="!showShrinkAmount">
        <input
          v-if="!showShrinkAmount"
          class="rectangle__input"
          type="text"
          v-model.trim="storeNumber"
          placeholder="4-Digit Store Number"
          maxlength="4"
        />
      </ShrinkRectangle>
      <small
        class="helper-text invalid"
        v-if="
          ($v.storeNumber.$dirty && !$v.storeNumber.required) ||
          !$v.storeNumber.numeric ||
          error
        "
        >Enter a valid store number</small
      >
    </template>
    <template v-slot:footer>
      <SubmitButton
        v-if="buttonState === 'enter'"
        @action="getShrinkValues"
        text="Enter"
        variant="contained"
      />
      <SubmitButton
        v-if="buttonState === 'clear'"
        @action="clearShrinkValues"
        text="Clear"
        variant="contained"
      />
    </template>
  </FinalLayout>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators";
import SubmitButton from "@/components/SubmitButton";
import ShrinkRectangle from "@/components/ShrinkRectangle";
import FinalLayout from "@/components/FinalLayout";
import logo from "../assets/images/icons/logo-with-hand.svg";

export default {
  name: "Final",
  components: {
    SubmitButton,
    ShrinkRectangle,
    FinalLayout,
  },
  data: () => ({
    storeNumber: "",
    showShrinkAmount: false,
    buttonState: "enter",
    shrink: null,
    logo,
    error: null,
  }),
  validations: {
    storeNumber: { required, numeric },
  },
  methods: {
    async getShrinkValues() {
      if (this.error) {
        this.error = "";
      }

      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      try {
        this.shrink = await this.$store.dispatch(
          "getShrinkByStore",
          this.storeNumber
        );
        this.showShrinkAmount = true;
        this.buttonState = "clear";
      } catch (error) {
        this.error = error;
      }
    },
    async clearShrinkValues() {
      this.showShrinkAmount = false;
      this.shrink = null;
      this.storeNumber = "";
      this.buttonState = "enter";
      this.$v.$reset();
    },
  },
};
</script>

<style scoped lang="scss">
.rectangle__input {
  border: none;
  outline: none;
  width: 100%;
  padding: 6rem;

  font-weight: 400;
  font-family: "RobotoRegular", sans-serif;
  font-size: 2.4rem;
  text-align: center;

  background: none;
  color: #ffffff;

  &::placeholder {
    color: #6d6b6b;
  }
}

.helper-text {
  position: absolute;
  left: calc(50% - 10rem);
  bottom: -3rem;

  font-family: "RosarioRegular", sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
}

.invalid {
  color: #f44336;
}

@media (max-width: 1440px) {
  .rectangle__input {
    font-size: 3rem;
  }
}

@media (max-width: 991px) {
  .rectangle__input {
    font-size: 1.8rem;
  }

  .helper-text {
    font-size: 1.6rem;
  }
}
</style>
