<template>
  <button
    class="button"
    type="button"
    @click="$emit('action')"
    v-text="text"
    :class="[size, variant]"
  ></button>
</template>

<script>
export default {
  name: "SubmitButton",
  props: {
    text: {
      type: String,
      required: true,
    },
    size: String,
    variant: String,
  },
};
</script>

<style scoped lang="scss">
.button {
  padding: 2rem 5rem;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5.6rem;
  border: none;
  width: 100%;

  font-family: inherit;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.2;

  color: #3e3e3f;

  outline: none;
  cursor: pointer;

  transition: text-decoration 0.3s linear;

  &:hover {
    text-decoration: underline;
  }
}

.md {
  font-size: 1.8rem;
}

.contained {
  background: #ffffff;
}

.outlined {
  border: 3px solid #ffffff;

  background: none;
  color: #ffffff;
}

@media (max-width: 1440px) {
  .button {
    padding: 2.5rem 6.25rem;
    border-radius: 7rem;
    font-size: 3rem;
  }

  .md {
    font-size: 2.25rem;
  }
}

@media (max-width: 991px) {
  .button {
    padding: 1.5rem 5rem;

    font-size: 1.5rem;
  }
}

@media (max-width: 668px) {
  .button {
    padding: 1rem 3rem;
  }
}
</style>
