<template>
  <div class="blur" />
</template>

<script>
export default {
  name: "BlurEffect",
};
</script>

<style scoped lang="scss">
.blur {
  position: absolute;

  width: 100%;
  height: 100%;
  border-radius: 37.3rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    border-radius: 37.3rem;

    backdrop-filter: blur(24px);
    background: rgba(0, 0, 0, 0.15);
  }
}

@media (max-width: 1440px) {
  .blur {
    border-radius: 46.625rem;

    &::before {
      border-radius: 46.625rem;
    }
  }
}
</style>
