<template>
  <div class="rectangle" @click="$emit('click')">
    <BlurEffect />
    <div class="rectangle__content">
      <slot v-if="showShrink"></slot>
      <template v-else>
        <div class="rectangle__content--left">
          <h3 class="rectangle__suptitle">total shrink</h3>
          <h2 class="rectangle__title">
            ${{ shrink.totalShrinkAmount | digit }}
          </h2>
          <h4 class="rectangle__subtitle">
            ${{ shrink.totalShrinkAmountPerDay | digit("del") }}/day
          </h4>
        </div>
        <div class="rectangle__content--right">
          <h3 class="rectangle__suptitle">operational shrink</h3>
          <h2 class="rectangle__title">
            ${{ shrink.operationalShrinkAmount | digit }}
          </h2>
          <h4 class="rectangle__subtitle">
            ${{ shrink.operationalShrinkAmountPerDay | digit("del") }}/day
          </h4>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import BlurEffect from "./BlurEffect";

export default {
  name: "ShrinkRectangle",
  props: ["shrink", "showShrink"],
  components: { BlurEffect },
};
</script>

<style scoped lang="scss">
.rectangle {
  position: relative;

  width: 81%;
  margin-inline: auto;
}

.rectangle__content {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  width: 100%;
  min-height: 19rem;
  padding: 2.66rem;
  border-radius: 37.3rem;
  border-width: 7px;
  border-style: dotted;
  border-right-color: #faac18;
  border-left-color: #f15a24;
  border-top-color: #fa8918;
  border-bottom-color: rgba(239, 115, 38, 0.98);

  font-family: "RobotoBold", sans-serif;
  font-weight: 700;
}

.rectangle__content--left {
  width: 49%;
  margin-right: 1rem;
}

.rectangle__content--right {
  width: 50%;
}

.rectangle__suptitle {
  width: min-content;
  margin-inline: auto;

  font-size: 1.6rem;
  text-transform: uppercase;

  color: #fbb03b;
}

.rectangle__title {
  font-size: 4.8rem;
  line-height: 1.4;
  text-transform: uppercase;
}

.rectangle__subtitle {
  font-size: 1.4rem;
}

@media (max-width: 1440px) {
  .rectangle {
    max-width: 55.6rem;
  }

  .rectangle__content {
    min-height: 23.75rem;
    border-radius: 46.625rem;
  }

  .rectangle__suptitle {
    font-size: 2rem;
  }

  .rectangle__title {
    font-size: 6rem;
  }

  .rectangle__subtitle {
    font-size: 1.75rem;
  }
}

@media (max-width: 991px) {
  .rectangle {
    max-width: 33rem;
  }

  .rectangle__content {
    padding: 1rem;
    min-height: 16rem;
  }

  .rectangle__suptitle {
    font-size: 1.6rem;
  }

  .rectangle__title {
    font-size: 3.2rem;
  }

  .rectangle__subtitle {
    font-size: 1.4rem;
  }
}
</style>
